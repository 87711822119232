import React, { useState } from 'react';
import {useDispatch} from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import store from '../../store/index';

export default function FilialForm() {

  let data = store.getState();
  
  const [codfilial, setFilial] = useState(() => {
    const initialState = data.filial === null ? "" : data.filial;
    return initialState;
  });

  const [frentista, setfrentista] = useState(() => {
    const initialState = data.frentista === null ? "" : data.frentista;
    return initialState;
  });

  const dispatch = useDispatch();
  const dataHoje = new Date();
  const dia = dataHoje.getDate(); 
  const mes = dataHoje.getMonth();
  const ano = dataHoje.getFullYear();
  const str_data = ano+'-'+(mes+1)+'-'+("0"+dia).slice(-2);
  const [date, setDate] = useState(str_data);
  

  const filiais = [
    {
      value: '0101',
      label: 'M. Aparecida',
    },
    {
      value: '0201',
      label: 'M. Belém',
    },
    {
      value: '0301',
      label: 'M. Frutal',
    },
    {
      value: '0401',
      label: 'M. Nova Olinda',
    },
    {
      value: '0402',
      label: 'M. Cariri I',
    },
    {
      value: '0501',
      label: 'M. Hidrolândia',
    },
    {
      value: '0601',
      label: 'M. Centralina',
    },
    {
      value: '0701',
      label: 'M. Cuiabá',
    },
    {
      value: '0801',
      label: 'M. Paraiso',
    },
    {
      value: '0901',
      label: 'M. Cariri II',
    },
  ];

  function addFilial(){
    dispatch({ type: 'ADD_FILIAL', filial: codfilial, frentista: frentista.toUpperCase(), date: date });
  };
  addFilial();

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Dados da Filial
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
        <TextField
          id="FilialName"
          name="FilialName"
          select
          label="Filial"
          required={true}
          fullWidth
          color="secondary"
          value={codfilial}
          onChange={event => setFilial(event.target.value)}
        >
          {filiais.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="Date"
            name="Date"
            label="Data"
            type="date"
            fullWidth
            color="secondary"
            defaultValue={str_data}
            autoComplete="lname"
            onChange={event => setDate(event.target.value)}
            InputLabelProps={{
                shrink: true,
              }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="Frentista"
            name="Frentista"
            label="Frentista"
            color="secondary"
            value={frentista}
            fullWidth
            onChange={event => setfrentista(event.target.value)}
            autoComplete="Nome Frentista"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}