import React from 'react';
import {useSelector} from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function Review() {
  const classes = useStyles();
  const data = useSelector(state => state);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Resumo da abordagem
      </Typography>
       <List disablePadding>
            <ListItem className={classes.listItem} >
                <ListItemText primary={"Filial:"}  />
                <Typography variant="body2">{data.filial}</Typography>
            </ListItem>
            <ListItem className={classes.listItem} >
                <ListItemText primary={"Data:"}  />
                <Typography variant="body2">{data.date}</Typography>
            </ListItem>
            <ListItem className={classes.listItem} >
                <ListItemText primary={"Frentista:"}  />
                <Typography variant="body2">{data.frentista}</Typography>
            </ListItem>
            <ListItem className={classes.listItem} >
                <ListItemText primary={"Empresa:"}  />
                <Typography variant="body2">{data.empresa}</Typography>
            </ListItem>
            <ListItem className={classes.listItem} >
                <ListItemText primary={"Placa:"}  />
                <Typography variant="body2">{data.placa}</Typography>
            </ListItem>
            <ListItem className={classes.listItem} >
                <ListItemText primary={"Litros:"}  />
                <Typography variant="body2">{data.litros}</Typography>
            </ListItem>
      </List> 
    </React.Fragment>
  );
}