import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';


import Approach from './pages/Approach';
import Company from './pages/Company';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';

export default function Routes() {
    return(
        
        <BrowserRouter>
            <Switch>
                <Route path='/' exact component={Login}/> 
                <Route path='/approach' component={Approach}/> 
                <Route path='/company' component={Company}/>
                <Route path='/dashboard' component={Dashboard}/> 
            </Switch>
        </BrowserRouter>
        
    );
}