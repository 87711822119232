import React, { useState } from 'react';
import {useDispatch} from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import store from '../../store';

export default function EmpresaForm() {

  let data = store.getState();

  const [empresaCompany, setEmpresa] = useState(() => {
    const initialState = data.empresaCompany === null ? "" : data.empresaCompany;
    return initialState;
  });
  const [concorrente, setConcorrente] = useState(() => {
    const initialState = data.concorrente === null ? "" : data.concorrente;
    return initialState;
  });
  const [preco_concorrente, setPrecoConcorrente] = useState(() => {
    const initialState = data.preco_concorrente === null ? "" : data.preco_concorrente;
    return initialState;
  });
  const [telefone, setTelefone] = useState(() => {
    const initialState = data.telefone === null ? "" : data.telefone;
    return initialState;
  });
  const [observacao, setObservacao] = useState(() => {
    const initialState = data.observacao === null ? "" : data.observacao;
    return initialState;
  });
  const dispatch = useDispatch();
  let precoConcorrente = null;

  if(preco_concorrente){
    precoConcorrente = preco_concorrente.replace(",",".");
  }

  
  function addEmpresa(){
    dispatch({ 
      type: 'ADD_EMPRESA_COMPANY', 
        empresaCompany: empresaCompany.toUpperCase(), 
        concorrente: concorrente.toUpperCase(), 
        preco_concorrente: precoConcorrente,
        telefone: telefone, 
        observacao: observacao 
    });
  };
 
  addEmpresa();  

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Dados Empresa
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField 
          required 
          id="empresaName" 
          label="Empresa"
          value={empresaCompany}
          onChange={event => setEmpresa(event.target.value)}
          color={'secondary'}
          fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField 
          required 
          id="concorrenteNumber" 
          label="Onde Abastece?"
          value={concorrente}
          onChange={event => setConcorrente(event.target.value)}
          color={'secondary'}
          fullWidth />  
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
          required 
          id="listroValor" 
          type="number" 
          label="Valor do litro" 
          value={preco_concorrente}
          onChange={event => setPrecoConcorrente(event.target.value)}
          color={'secondary'}
          fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
          required 
          id="Telefone" 
          type="number" 
          label="Telefone" 
          value={telefone}
          onChange={event => setTelefone(event.target.value)}
          color={'secondary'}
          fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField 
          required 
          id="observacao" 
          label="Observação" 
          multiline={true}
          value={observacao}
          onChange={event => setObservacao(event.target.value)}
          color={'secondary'}
          fullWidth />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}