import React, { useState } from 'react';
import {useDispatch} from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';


import store from '../../store/index';

export default function FilialForm() {

  let data = store.getState();
  
  const [codfilial, setFilial] = useState(() => {
    const initialState = data.filialCompany === null ? "" : data.filialCompany;
    return initialState;
  });

  //const [codfilial, setFilial] = useState('');
  const [representante, setRepesentante] = useState(() => {
    const initialState = data.representante === null ? "" : data.representante;
    return initialState;
  });
  
  const dataHoje = new Date();
  const dia = dataHoje.getDate(); 
  const mes = dataHoje.getMonth();
  const ano = dataHoje.getFullYear();
  const str_data = ano+'-'+ ("0"+mes).slice(-2) +'-'+("0"+dia).slice(-2);
  const [dateCompany, setDateCompany] = useState(str_data);
  const dispatch = useDispatch();

  const filiais = [
    {
      value: '0101',
      label: 'M. Aparecida',
    },
    {
      value: '0201',
      label: 'M. Belém',
    },
    {
      value: '0301',
      label: 'M. Frutal',
    },
    {
      value: '0401',
      label: 'M. Nova Olinda',
    },
    {
      value: '0402',
      label: 'M. Cariri I',
    },
    {
      value: '0501',
      label: 'M. Hidrolândia',
    },
    {
      value: '0601',
      label: 'M. Centralina',
    },
    {
      value: '0701',
      label: 'M. Cuiabá',
    },
    {
      value: '0801',
      label: 'M. Paraiso',
    },
    {
      value: '0901',
      label: 'M. Cariri II',
    },
  ];

  function addFilial(){
    dispatch({ 
      type: 'ADD_FILIAL_COMPANY', 
      filialCompany: codfilial, 
      representante: representante.toUpperCase(), 
      dateCompany: dateCompany 
    });
  };
  addFilial();

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Dados da Filial
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
        <TextField
          id="FilialName"
          name="FilialName"
          select
          label="Filial"
          required={true}
          color="secondary"
          fullWidth
          value={codfilial}
          onChange={event => setFilial(event.target.value)}
        >
          {filiais.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="Date"
            name="Date"
            label="Data"
            type="date"
            color="secondary"
            fullWidth
            defaultValue={str_data}
            autoComplete="lname"
            onChange={event => setDateCompany(event.target.value)}
            InputLabelProps={{
                shrink: true,
              }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="Representante"
            name="Representante"
            label="Representante"
            value={representante}
            fullWidth
            color="secondary"
            //style={{ textTransform: 'uppercase'}}
            onChange={event => setRepesentante(event.target.value)}
            autoComplete="Nome Representante"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}