import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { red } from '@material-ui/core/colors';

import ButtonNovo from '../Component/Button';
import Copyright from '../Copyright';
import logo from '../../logo_completa.png';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ButtonRed = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(red[600]),
      backgroundColor: red[600],
      '&:hover': {
        backgroundColor: red[900],
      },
    },
  }))(Button);

export default function SignIn({history}) {
  const classes = useStyles();

  function handleHistoryCompany(e){
      e.preventDefault();
      history.push('/company');
  }

  function handleHistoryApproach(e){
      e.preventDefault();
      history.push('/approach');
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <img src={logo} className="App-logo" alt="logo" height="100" />
        <Typography component="h1" variant="h5">
          Dashboard
        </Typography>
        <form className={classes.form} noValidate>
          <ButtonNovo
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleHistoryCompany}
            className={classes.submit}
          >
           Abordagem Empresa
          </ButtonNovo>
          <ButtonRed
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleHistoryApproach}
            className={classes.submit}
          >
           Abordagem Patio
          </ButtonRed>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}