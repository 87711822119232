import { createStore } from 'redux';

const INITIAL_STATE = {
    //data: [],
    filial: null,
    frentista: null,
    date: null,
    empresa: null,
    placa: null,
    litros: null,
    filialCompany: null,
    representante: null,
    dateCompany: null,
    empresaCompany: null,
    concorrente: null,
    preco_concorrente: null,
    telefone: null,
    observacao: null,
};

function companies( state = INITIAL_STATE, action ){

    switch (action.type) {
        case 'ADD_FILIAL':
            return { 
                ...state, 
                filial: action.filial, 
                frentista: action.frentista, 
                date: action.date,
            };
        case 'ADD_EMPRESA':
            return { 
                ...state, 
                empresa: action.empresa,
                placa: action.placa,
                litros: action.litros,
            };
        case 'ADD_FILIAL_COMPANY':
            return { 
                ...state, 
                filialCompany: action.filialCompany, 
                representante: action.representante, 
                dateCompany: action.dateCompany,
            };            
        case 'ADD_EMPRESA_COMPANY':
            return { 
                ...state, 
                empresaCompany: action.empresaCompany,
                concorrente: action.concorrente,
                preco_concorrente: action.preco_concorrente,
                telefone: action.telefone,
                observacao: action.observacao,
            };
        case 'REMOVE_STATE':
                return { 
                        filial: null,
                        frentista: null,
                        date: null,
                        empresa: null,
                        placa: null,
                        litros: null,
                        filialCompany: null,
                        representante: null,
                        dateCompany: null,
                        empresaCompany: null,
                        concorrente: null,
                        preco_concorrente: null,
                        telefone: null,
                        observacao: null,
                };
        default:
            return state;
    }
}

const store = createStore(companies);

export default store;