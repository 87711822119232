import React from 'react';
import {useDispatch} from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';

import { toast } from 'react-toastify';

import FilialForm from './FilialForm';
import EmpresaForm from './EmpresaForm';
import Review from './Review';
//import Save from './Save';

import Copyright from '../Copyright';
import ButtonNovo from '../Component/Button';

import api from '../../services/api';
import store from '../../store';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    backgroundColor: '#fff',
    root: {
      backgroundColor: '#fff',
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 25,
    height: 25,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
      backgroundColor: red[600],

  },
  completed: {
    backgroundColor: red[800],
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: '1',
    2: '2',
    3: '3',
    //4: '4',
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = ['Dados da Filial', 'Dados Empresa', 'Resumo'/*, ''*/];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <FilialForm />;
    case 1:
      return <EmpresaForm />;
    case 2:
      return <Review />;
    /*case 3:
        return <Save />;*/
    default:
      throw new Error('Unknown step');
  }
}


export default function Approach({ history}) {

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  
  let data = store.getState();
  let response = null;
  const dispatch = useDispatch();

  async function handlePost(){
    
    toast.configure();

    const cadSuccess = () => toast.success("😎 Dados cadastrado com sucesso!",{
      autoClose: 3000
    });

    const filialNull = () => toast.warn("Preencha o campo FILIAL na aba nº 1",{
      autoClose: 3000
    });

    const dateNull = () => toast.warn("Preencha o campo DATA na aba nº 1",{
      autoClose: 3000
    });

    const frentistalNull = () => toast.warn("Preencha o campo FRENTISTA na aba nº 1",{
      autoClose: 3000
    });             


    if(data.filial === null || data.filial === ''){
      filialNull();
      return false;
    }
    else if(data.date === null || data.date === ''){
      dateNull();
      return false;
    }
    else if(data.frentista === null || data.frentista === ''){
      frentistalNull();
      return false;
    }
    else {
      response = await api.post('/approach',{
        "filial": data.filial,
        "data": data.date,
        "frentista": data.frentista,
        "empresa": data.empresa,
        "placa": data.placa,
        "litros": data.litros
      });

      cadSuccess();
      history.push('/dashboard');

      dispatch({ type: 'REMOVE_STATE' });
    }
    
    return response;
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

 // if(activeStep === 3){
 //   history.push('/dashboard');
 //}

  return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
            <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
                Abordagem Patio
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map(label => (
                <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <React.Fragment>
                {activeStep === steps.length ? (
                <React.Fragment>
    
                </React.Fragment>
                ) : (
                <React.Fragment>
                    {getStepContent(activeStep)}
                    <div className={classes.buttons}>
                    {activeStep !== 0 && (
                        <Button onClick={handleBack} className={classes.button}>
                        Voltar
                        </Button>
                    )}
                    <ButtonNovo
                        variant="contained"
                        color="primary"
                        //onClick={handleNext}
                        onClick={activeStep === steps.length - 1 ? handlePost : handleNext}
                        className={classes.button}
                    >
                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                    </ButtonNovo>
                    </div>
                </React.Fragment>
                )}
            </React.Fragment>
            </Paper>
            <Copyright />
        </main>
      </React.Fragment>
  );
}