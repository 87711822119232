import {  withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { red } from '@material-ui/core/colors';

const ButtonRed = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(red[600]),
      backgroundColor: red[600],
      '&:hover': {
        backgroundColor: red[900],
      },
    },
  }))(Button);

export default ButtonRed;