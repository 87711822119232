import React, { useState } from 'react';
import {useDispatch} from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import store from '../../store/index';

export default function EmpresaForm() {

  let data = store.getState();

  const [empresa, setEmpresa] = useState(() => {
    const initialState = data.empresa === null ? "" : data.empresa;
    return initialState;
  });
  const [placa, setPlaca] = useState(() => {
    const initialState = data.placa === null ? "" : data.placa;
    return initialState;
  });
  const [litros, setLitros] = useState(() => {
    const initialState = data.litros === null ? "" : data.litros;
    return initialState;
  });

  let litragem = null;

  if(litros){
    litragem = litros.replace(",",".");
  }
  const dispatch = useDispatch();



  function addEmpresa(){
    dispatch({ 
      type: 'ADD_EMPRESA', 
      empresa: empresa.toUpperCase(), 
      placa: placa.toUpperCase(), 
      //litros: litros 
      litros: litragem
    });
  };
 
  addEmpresa();

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Dados Empresa
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField 
          required 
          id="empresaName" 
          label="Empresa"
          value={empresa}
          onChange={event => setEmpresa(event.target.value)}
          fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
          required 
          id="placaNumber" 
          label="Placa" 
          value={placa}
          onChange={event => setPlaca(event.target.value)}
          fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
          required 
          id="listrosQTD" 
          type="number" 
          value={litros}
          label="Litros Abastecidos" 
          onChange={event => setLitros(event.target.value)}
          fullWidth />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}