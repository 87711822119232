import React, {useState} from 'react';

import api from '../../services/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../logo.png';

import ButtonNovo from '../Component/Button';
import Copyright from '../Copyright';
  
  const useStyles = makeStyles(theme => ({
    '@global': {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

export default function Login({ history }){
  const classes = useStyles();
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');

  async function handleSubmit(event){
    event.preventDefault();
    toast.configure();
    const errorLogin = () => toast.error("😓 Usuário ou Senha inválidos");
    const errorPost = () => toast.error("😭Falha ao conectar com Servidor backend");
    const errorProtheus = () => toast.error("😭Falha ao conectar com Servidor Protheus");
    const loginSuccess = () => toast.success("😎 Tudo certo! Estamos redirecionando para o dashborad.",{
        autoClose: 2000
    });
    await api.post('/login',{
      "CLOGIN": user,
      "CPASSWORD": pass,
    })
    .then(function a (response) {
        if (response.data.fail === 'Falha ao comunicar com Protheus'){
            errorProtheus();

        }else if(response.data.fail === 'login invalido'){
            errorLogin();
        }else{
          
          loginSuccess();
          setTimeout(() => {
             history.push('/dashboard');
            }, 500);
        }
    })
    .catch(function (error) {
        console.log(error);
        errorPost();
     });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} className="App-logo" alt="logo" height="100" />
        <Typography component="h1" variant="h5">
          Comercial Rede Marajó
        </Typography> 
        
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="user"
            color="secondary"
            label="Usuário"
            name="user"
            autoComplete="Usuário"
            autoFocus
            value={user}
            onChange={event => setUser(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            color="secondary"
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            value={pass}
            onChange={event => setPass(event.target.value)}
          />
          <ButtonNovo
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </ButtonNovo>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
